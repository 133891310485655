"use client"

import { useEffect, useState } from "react"

const ScrollTopAndComment = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const handleWindowScroll = () => {
      if (window.scrollY > 50) setShow(true)
      else setShow(false)
    }

    window.addEventListener("scroll", handleWindowScroll)
    return () => window.removeEventListener("scroll", handleWindowScroll)
  }, [])

  const handleScrollTop = () => {
    window.scrollTo({ top: 0 })
  }
  return (
    <div
      className={`fixed bottom-20 right-8 z-[39] hidden flex-col gap-3 ${show ? "md:flex" : "md:hidden"}`}
    >
      <button
        aria-label="Scroll To Top"
        aria-labelledby="Scroll To Top"
        title="Scroll To Top"
        onClick={handleScrollTop}
        className="bg-primary-500 hover:bg-primary-500/50 rounded-full p-2 text-white transition-all"
      >
        <svg className="size-5" viewBox="0 0 20 20" fill="currentColor">
          <path
            fillRule="evenodd"
            d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  )
}

export default ScrollTopAndComment
